import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Mailchimp from './Mailchimp';

const Subscribe = props => {
    const {
        className
    } = props;

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    useEffect(() => {
        const timer = setTimeout(() => {
            toggle();
        }, 5000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <Modal isOpen={modal} toggle={toggle} className={className} centered>
                <ModalHeader className="p-0">
                    <img src="/assets/img/subscribe_modal_header.jpg" className="w-100" />
                </ModalHeader>
                <ModalBody>
                <Mailchimp
                    toggle={toggle}
                    action='https://photoswithausten.us8.list-manage.com/subscribe/post?u=b5599973f608310a3d05e5c83&amp;id=74151f898e'
                    isModal={true}
                    fields={[
                        {
                            name: 'EMAIL',
                            placeholder: 'example@email.com',
                            type: 'email',
                            required: true
                        }
                    ]}
                />
                </ModalBody>
            </Modal>
        </>
    );
};

export default Subscribe;
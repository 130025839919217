import React, { useState, useEffect } from 'react';
import PrimaryNav from './Navbar';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false),
        [offset, setOffset] = useState(0),
        toggle = () => setIsOpen(!isOpen);

    const parallaxShift = () => {
        setOffset(window.pageYOffset)
    };

    useEffect(() => {
        // Add parallax event listener on header mount
        window.addEventListener('scroll', parallaxShift);

        // Remove parallax event listener on header unmount
        return () => {
            window.removeEventListener('scroll', parallaxShift);
        } 
    }, []);

    return (
        <header className="App" style={{
            backgroundImage: `linear-gradient(to top, rgba(108,55,114, 0.52), rgba(117, 19, 93, 0.73)), url('https://firebasestorage.googleapis.com/v0/b/pwitha-3d4f2.appspot.com/o/img%2Fglobal%2Fheader.jpg?alt=media&token=fbbf1f6b-31f5-48d2-b07d-b44260a857ab')`,
            backgroundPositionY: offset * 0.45
        }}
        >
            <div className="container">
                <PrimaryNav />
                <img src={'https://firebasestorage.googleapis.com/v0/b/pwitha-3d4f2.appspot.com/o/img%2Fglobal%2Flogo.svg?alt=media&token=fd7142dd-925d-451f-b355-6556e82e33e9'}
                    style={{
                        margin: '0 auto',
                        maxWidth: 650,
                        width: '100%',
                        display: 'block',
                        padding: '100px 25px'
                    }}
                    alt='Photos With Austen'
                />
            </div>
        </header>
    );
};

export default Header;
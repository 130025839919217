import React from 'react';
import EveryOccasion from '../components/home/EveryOccasion';
import Testimonials from '../components/home/Testimonials';
import Bio from '../components/home/Bio';
import Subscribe from '../components/global/Subscribe';

const Home = props => {
    return (
        <>
            <EveryOccasion />
            <Testimonials />
            <Bio />
            <Subscribe />
        </>
    );
};

export default Home;
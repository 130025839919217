import React from 'react';
import { Row, Col } from 'reactstrap';

const EveryOccasion = props => {
    const occasions = [
        {
            src: 'https://firebasestorage.googleapis.com/v0/b/pwitha-3d4f2.appspot.com/o/img%2Fhome%2Fweddings.jpg?alt=media&token=82f7f216-f28d-4d1c-a28c-06034fb00b3a',
            webp: 'https://firebasestorage.googleapis.com/v0/b/pwitha-3d4f2.appspot.com/o/img%2Fhome%2Fweddings.webp?alt=media&token=4367d10b-bf83-4ac0-90a8-f83eac6b133f',
            occ: 'Weddings'
        },
        {
            src: 'https://firebasestorage.googleapis.com/v0/b/pwitha-3d4f2.appspot.com/o/img%2Fhome%2Ffamilies.jpg?alt=media&token=99dbefd9-116c-4b5c-bb9f-3a6e42352c54',
            webp: 'https://firebasestorage.googleapis.com/v0/b/pwitha-3d4f2.appspot.com/o/img%2Fhome%2Ffamilies.webp?alt=media&token=b4b3fafb-4f77-4c38-907b-60016c4f90e2',
            occ: 'Families'
        },
        {
            src: 'https://firebasestorage.googleapis.com/v0/b/pwitha-3d4f2.appspot.com/o/img%2Fhome%2Fsr_photos.jpg?alt=media&token=ae33f78a-218c-4090-a3de-fec7e02f38c3',
            webp: 'https://firebasestorage.googleapis.com/v0/b/pwitha-3d4f2.appspot.com/o/img%2Fhome%2Fsr_photos.webp?alt=media&token=fefa3a56-5e02-4822-9a40-4b564c420dd9',
            occ: 'Senior Photos'
        },
        {
            src: 'https://firebasestorage.googleapis.com/v0/b/pwitha-3d4f2.appspot.com/o/img%2Fhome%2Fportraits.jpg?alt=media&token=97e3e342-3d89-430e-bf89-cb3816bf21fc',
            webp: 'https://firebasestorage.googleapis.com/v0/b/pwitha-3d4f2.appspot.com/o/img%2Fhome%2Fportraits.webp?alt=media&token=76e7c67c-3b74-4c8b-8cb6-120c8d4855d5',
            occ: 'Portraits'
        }
    ];

    return (
        <section className='container pt-5'>
            <h4 className='text-center mb-5' style={{ color: '#6c3772', fontWeight: 300 }}>
                PERFECT FOR <span style={{ fontFamily: 'Satisfy', fontSize: '2.2em' }}>every</span> OCCASSION
            </h4>
            <Row>
                {occasions.map(occasion => (
                    <Col md="3" sm="6" className='mb-3' key={occasion.occ}>
                        <div className="occasions">
                            <picture>
                                <source srcSet={occasion.src} />
                                <source srcSet={occasion.webp} />
                                <img src={occasion.src} style={{ width: '100%' }} className='image' alt={occasion.occ} />
                            </picture>
                            <div className="middle">
                                <div className="text">{occasion.occ}</div>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
        </section>
    );
};

export default EveryOccasion;
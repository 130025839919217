import React from "react"
import jsonp from "jsonp"
import PropTypes from 'prop-types';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';

class Mailchimp extends React.Component {
    state = {};

    handleSubmit(evt) {
        evt.preventDefault();
        const { fields, action } = this.props;
        const values = fields.map(field => {
            return `${field.name}=${encodeURIComponent(this.state[field.name])}`;
        }).join("&");
        const path = `${action}&${values}`;
        const url = path.replace('/post?', '/post-json?');
        const regex = /^([\w_\.\-\+])+\@([\w\-]+\.)+([\w]{2,10})+$/;
        const email = this.state['EMAIL'];
        (!regex.test(email)) ? this.setState({ status: "empty" }) : this.sendData(url);
    };

    sendData(url) {
        this.setState({ status: "sending" });
        jsonp(url, { param: "c" }, (err, data) => {
            if (data.msg.includes("already subscribed")) {
                this.setState({ status: 'duplicate' });
            } else if (err) {
                this.setState({ status: 'error' });
            } else if (data.result !== 'success') {
                this.setState({ status: 'error' });
            } else {
                this.setState({ status: 'success' });
            };
        });
    }

    render() {
        const { fields, styles, isModal } = this.props;
        const messages = {
            ...Mailchimp.defaultProps.messages,
            ...this.props.messages
        }
        const { status } = this.state;
        return (
            <>
                {status === "sending" && <div class="alert alert-default" role="alert">{messages.sending}</div>}
                {status === "success" && (
                    <>
                        <div class="alert alert-success" role="alert">{messages.success}</div>
                        <Button className="w-100" color="white" onClick={this.props.toggle}>
                            Close
                        </Button>
                    </>
                )}
                {status === "duplicate" && <div class="alert alert-warning" role="alert">{messages.duplicate}</div>}
                {status === "empty" && <div class="alert alert-warning" role="alert">{messages.empty}</div>}
                {status === "error" && <div class="alert alert-danger" role="alert">{messages.error}</div>}
                
                {status !== "success" && (
                    <Form onSubmit={this.handleSubmit.bind(this)}>
                        
                        {fields.map(input =>
                            <Input
                                {...input}
                                key={input.name}
                                onChange={({ target }) => this.setState({ [input.name]: target.value })}
                                defaultValue={this.state[input.name]}
                            />
                        )}
                        <div className="row pt-3">
                        {isModal && (
                            <span className="col-5 pr-0">
                                <Button className="w-100" color="white" onClick={this.props.toggle}>
                                    {['duplicate', 'error'].includes(status) ? 'Close' : 'No Thanks'}
                                </Button>
                            </span>
                        )}
                            <span className="col-7">
                                <Button
                                    disabled={status === "sending" || status === "success"}
                                    type="submit"
                                    color="primary"
                                    className="w-100"
                                >
                                    {messages.button}
                                </Button>
                            </span>
                        </div>
                        <div className="mt-3" style={{ fontSize: "0.6rem", lineHeight: 1 }}>
                            <em>
                                By submitting this form you agree to receive promotional and transactional emails from Photos With Austen. Your info will only every be used to contact you with special offers or updates on services.
                            </em>
                        </div>
                    </Form>
                )}

            </>
        );
    }
}

Mailchimp.defaultProps = {
    isModal: false,
    messages: {
        sending: "Sending...",
        success: "Thank you for subscribing!",
        error: "An unexpected internal error has occurred.",
        empty: "Please enter your email address to subscribe.",
        duplicate: "You are already subscribed!",
        button: "Subscribe"
    },
    styles: {
        sendingMsg: {
            color: "#0652DD"
        },
        successMsg: {
            color: "#009432"
        },
        duplicateMsg: {
            color: "#EE5A24"
        },
        errorMsg: {
            color: "#ED4C67"
        }
    }
};

Mailchimp.propTypes = {
    action: PropTypes.string,
    messages: PropTypes.object,
    fields: PropTypes.array,
    styles: PropTypes.object,
    className: PropTypes.string,
    buttonClassName: PropTypes.string
};

export default Mailchimp;
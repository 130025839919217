import React, { useState } from 'react';
import {
    Navbar,
    NavbarBrand,
    NavbarToggler,
    Collapse,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';

const PrimaryNav = () => {
    const [isOpen, setIsOpen] = useState(false),
        toggle = () => setIsOpen(!isOpen);
    return (
        <Navbar color="transparent" dark expand="md">
            <NavbarBrand href='/'>
                <img src={'/assets/img/logo_nav.svg'} style={{ height: 30 }} alt='Logo' />
            </NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
                <Nav className="ml-auto" navbar>
                    <NavItem>
                        <NavLink href='/'>HOME</NavLink>
                    </NavItem>
                </Nav>
            </Collapse>
        </Navbar>
    );
};

export default PrimaryNav;
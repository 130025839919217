import React from 'react';

const Bio  = () => {
    return (
        <section className='container mt-3'>
            <div className='row'>
                <div className='col-lg-3 col-md-4 col-sm-5 p-5'>
                    <picture>
                        <source srcSet={'https://firebasestorage.googleapis.com/v0/b/pwitha-3d4f2.appspot.com/o/img%2Fhome%2Fausten.jpg?alt=media&token=fb70a86e-2b08-4f77-b5c0-6e53c21c943c'} />
                        <source srcSet={'https://firebasestorage.googleapis.com/v0/b/pwitha-3d4f2.appspot.com/o/img%2Fhome%2Fausten.webp?alt=media&token=973ce280-5fff-4e8c-b772-87f41c8de628'} />
                        <img src={'https://firebasestorage.googleapis.com/v0/b/pwitha-3d4f2.appspot.com/o/img%2Fhome%2Fausten.jpg?alt=media&token=fb70a86e-2b08-4f77-b5c0-6e53c21c943c'} style={{ maxWidth: '100%' }} alt="Austen Wilks" />
                    </picture>
                </div>
                <div className='col-lg-9 col-md-8 col-sm-7 p-5' style={{ color: '#6a3770' }}>
                    <h5 className="mb-3">I'M AUSTEN.</h5>
                    <p>I enjoy life most when I get to meet new people, be flexible, and travel... So, I became a photographer :)</p>
                    <p>I am a portrait and lifestyle photographer. My goal is for you to have a good time, relax, and enjoy being the center of attention for a while.</p>
                    <p>Hope to see you soon!</p>
                </div>
            </div>
        </section>
    );
};

export default Bio;
import React from 'react';

const Testimonials = () => {
    return (
        <section style={{ backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/pwitha-3d4f2.appspot.com/o/img%2Fhome%2Ftestimonials.jpg?alt=media&token=b05ae352-dcdf-4fdd-88dd-6305641bfa1d')` }} className='py-3 mt-5'>
            <div className='container text-center p-5' style={{ color: 'rgb(108, 55, 114)' }}>
                <h5 style={{ fontWeight: 300 }}>
                    <em>
                        I could not recommend Austen highly enough! She was so good with our 9 month old, she was great at giving direction, she made us feel comfortable and loved. Getting good pictures of a crawling kid is never easy, but she nailed it. Austin is super kind, very encouraging, and honestly the pictures were stunning! HIGHLY Recommended!
                    </em>
                </h5>
                <p className='mt-4 mb-0'>-Lynsey C.</p>
            </div>
        </section>
    )
}

export default Testimonials;
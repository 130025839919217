import React from 'react';

const Footer = () => {
    return (
        <footer style={{ background: '#6d265c', textAlign: 'center', color: '#ffffff', fontSize: 12, padding: 10 }}>
            <p style={{ margin: 0 }}>© 2020 Photos With Austen | Site by <a href="https://redmanedigital.com/?ref=pwa" target="_blank" style={{ color: '#ffffff' }}>Redmane Digital</a></p>
        </footer>
    );
};

export default Footer;
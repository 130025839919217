import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Home from './pages/Home';
import Header from './components/global/Header';
import Footer from './components/global/Footer';

const App = props => {
    return (
      <>
        <Header />
        <Router>
          <Switch>
            <Route exact path='/'>
              <Home />
            </Route>
            <Redirect to='/' />
          </Switch>
        </Router>
        <Footer />
      </>
    );
};

export default App;